import React from 'react';
import Banner from '../components/home/Banner';
import KeyFeatures from '../components/home/Keyfeatures';
import Services from '../components/home/Services';
import Reviews from '../components/home/Reviews';
import CallToContact from '../components/comman/CallToContact';
import { urbanHausMessage, keyFeaturesData, servicesData, testimonialsData } from '../data/data';

function Home() {
  return (
    <div>
      <Banner title={urbanHausMessage.title} description={urbanHausMessage.description} />
      <KeyFeatures features={keyFeaturesData} />
      <Services data={servicesData} />
      <Reviews testimonialsData={testimonialsData} />
      <CallToContact />
    </div>
  );
}

export default Home;
