import React from 'react';


const CommonHero = () => {
  return (
    <section className="page-hero py-16">
      <div className="container">
        <div className="text-center">
          {/* Add any centered content here if needed */}
        </div>
        <div className="page-hero-content mx-auto max-w-[768px] text-center">
          <h1 className="mb-5 mt-8">We'll Be Back Soon!</h1>
          <p>
            Our store is temporarily closed, but we'll be reopening shortly. Stay tuned for updates!
          </p>
          <div className="mt-11 justify-center sm:flex">
            <a className="btn btn-primary m-3 block sm:inline-block" href="contact.html">Contact Us</a>
            <a className="btn btn-outline-primary m-3 block min-w-[160px] sm:inline-block" href="about.html">Learn More</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommonHero;
