import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const GallerySection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
      once: true, // Animation only occurs once
    });
  }, []);

  return (
    <section className="section">
      <div className="container">
        <div className="row justify-center text-center">
          <div className="col-8">
            <h2 data-aos="fade-up">We started with one single goal: Empower entrepreneurs</h2>
          </div>
        </div>
        <div className="row mt-2.5">
          {[
            { src: 'images/about/gallery-img-1.png', width: 480, height: 328 },
            { src: 'images/about/gallery-img-2.png', width: 480, height: 274 },
            { src: 'images/about/gallery-img-3.png', width: 480, height: 540 },
            { src: 'images/about/gallery-img-1.png', width: 480, height: 328 },
            { src: 'images/about/gallery-img-2.png', width: 480, height: 274 },
            { src: 'images/about/gallery-img-3.png', width: 480, height: 540 },
            { src: 'images/about/gallery-img-1.png', width: 480, height: 328 },
            { src: 'images/about/gallery-img-2.png', width: 480, height: 274 },
            { src: 'images/about/gallery-img-3.png', width: 480, height: 540 },
          ].map((image, index) => (
            <div
              key={index}
              className="md:col-6"
              data-aos="fade-up"
              data-aos-delay={`${index * 100}`} // Staggered animation delays
            >
              <div className="relative mt-8">
                <img
                  className="w-full object-cover"
                  width={image.width}
                  height={image.height}
                  src={image.src}
                  alt={`Gallery image ${index + 1}`}
                />
                {index === 1 && (
                  <img
                    className="absolute -bottom-5 -left-5 -z-[1]"
                    src="images/shape-2.svg"
                    alt="Decorative Shape"
                  />
                )}
                {index === 2 && (
                  <img
                    className="absolute -bottom-4 -right-5 -z-[1] h-16 w-16"
                    src="images/shape.svg"
                    alt="Decorative Shape"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default GallerySection;
