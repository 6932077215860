import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const values = [
  {
    number: '01',
    title: 'Creativity',
    description: 'At Urban Haus, we push boundaries to craft unique, inspiring designs that make a memorable impact on our clients.',
    color: 'gradient',
    bg: 'images/gradient-number-bg.svg',
  },
  {
    number: '02',
    title: 'Quality',
    description: 'We commit to delivering exceptional craftsmanship and high-quality standards in every project, ensuring client satisfaction.',
    color: 'gradient',
    bg: 'images/gradient-number-bg.svg',
  },
  {
    number: '03',
    title: 'Innovation',
    description: 'Urban Haus embraces new ideas and technology, seeking innovative ways to advance the interior design and furniture industry.',
    color: 'gradient',
    bg: 'images/gradient-number-bg.svg',
  },
  {
    number: '04',
    title: 'Customer Focus',
    description: 'We focus on our customers’ needs, striving to exceed expectations through tailored designs and attentive service in every project.',
    color: 'gradient',
    bg: 'images/gradient-number-bg.svg',
  },
  {
    number: '05',
    title: 'Collaboration',
    description: 'Collaboration is central to Urban Haus, where we work together to achieve outstanding results and foster a creative environment.',
    color: 'gradient',
    bg: 'images/gradient-number-bg.svg',
  },
  {
    number: '06',
    title: 'Sustainability',
    description: 'Urban Haus is dedicated to sustainable design practices, creating spaces that reduce environmental impact and Being eco-friendly.',
    color: 'gradient',
    bg: 'images/gradient-number-bg.svg',
  },
];


const ValuesSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
      once: true, // Animation only occurs once
    });
  }, []);

  return (
    <section className="section">
      <div className="container">
        <div className="row items-center justify-between">
          <div className="md:col-5">
            <h2 className="text-center md:text-left" data-aos="fade-up">
              Our Core Values <br />
              Drive Everything We Do
            </h2>
          </div>
          <div className="mt-6 text-center md:col-3 md:mt-0 md:text-right">
            <a className="btn btn-primary" href="contact" data-aos="fade-up" data-aos-delay="200">
              Contacts
            </a>
          </div>
        </div>
        <div className="row mt-14">
          {values.map((value, index) => (
            <div
              key={index}
              className="mb-8 sm:col-6 lg:col-4"
              data-aos="fade-up"
              data-aos-delay={`${index * 100}`} // Staggered animation delays
            >
              <div className="rounded-xl bg-white p-6 shadow-lg lg:p-8">
                <div className="gradient-number relative inline-block">
                  <span className={`bg-${value.color} absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}>
                    {value.number}
                  </span>
                  <img src={value.bg} alt="" />
                </div>
                <h4 className="my-6">{value.title}</h4>
                <p>{value.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ValuesSection;
