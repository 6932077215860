import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const Services = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS with options
    return () => {
      AOS.refresh(); // Clean up AOS instance
    };
  }, []);

  return (
    <section className="section services py-16 bg-gray-50">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center">
          <div className="lg:w-1/2 lg:pr-8">
            <h2 className="text-3xl lg:text-4xl font-bold mb-4" data-aos="fade-up" data-aos-delay="400">
              Elevate Your Space with Urban Haus
            </h2>
            <p className="text-lg leading-relaxed mb-6" data-aos="fade-up" data-aos-delay="500">
              At Urban Haus, we specialize in creating beautiful, high-quality spaces that are affordable and designed to suit your lifestyle. Our commitment to excellence ensures that every project we undertake not only meets but exceeds your expectations.
            </p>
            <ul className="tab-nav border-b border-gray-200 mb-8">
              <li
                className={`tab-nav-item cursor-pointer ${activeTab === 0 ? 'active' : ''}`}
                onClick={() => handleTabChange(0)}
                data-aos="fade-up"
                data-aos-delay="600"
              >
                Interior Designs: Innovative and modern designs to transform your space
              </li>
              <li
                className={`tab-nav-item cursor-pointer ${activeTab === 1 ? 'active' : ''}`}
                onClick={() => handleTabChange(1)}
                data-aos="fade-up"
                data-aos-delay="700"
              >
                Detailed Furniture Designs: Top-quality materials and craftsmanship for lasting beauty
              </li>
              <li
                className={`tab-nav-item cursor-pointer ${activeTab === 2 ? 'active' : ''}`}
                onClick={() => handleTabChange(2)}
                data-aos="fade-up"
                data-aos-delay="800"
              >
                General Consulting: Affordable solutions without compromising on excellence
              </li>
            </ul>
          </div>
          <div className="lg:w-1/2">
            {activeTab === 0 && (
              <div className="tab-content-panel" data-aos="fade-up" data-aos-delay="100">
                <img
                  className="w-full object-cover rounded-lg"
                  src="https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?cs=srgb&dl=pexels-fotoaibe-1571460.jpg&fm=jpg"
                  alt="Interior Designs"
                />
              </div>
            )}
            {activeTab === 1 && (
              <div className="tab-content-panel" data-aos="fade-up" data-aos-delay="200">
                <img
                  className="w-full object-cover rounded-lg"
                  src="https://www.decorilla.com/online-decorating/wp-content/uploads/2022/11/Luxury-interior-design-home-designing-1-scaled.jpeg"
                  alt="Detailed Furniture Designs"
                />
              </div>
            )}
            {activeTab === 2 && (
              <div className="tab-content-panel" data-aos="fade-up" data-aos-delay="300">
                <img
                  className="w-full object-cover rounded-lg"
                  src="https://jumanji.livspace-cdn.com/magazine/wp-content/uploads/sites/2/2021/06/10131120/interior-wall-design.jpg"
                  alt="General Consulting"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
