import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const CallToContact = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS with options
  }, []);

  return (
    <section className="px-5 py-20 xl:py-[120px]" data-aos="fade-up">
      <div className="container">
        <div
          className="bg-gradient row justify-center rounded-b-[80px] rounded-t-[20px] px-[30px] pb-[75px] pt-16 transition-transform duration-500 ease-in-out hover:scale-105"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="lg:col-11">
            <div className="row">
              <div className="lg:col-7" data-aos="fade-right" data-aos-delay="200">
                <h2 className="text-3xl lg:text-4xl font-bold text-white transition-transform duration-300 ease-in-out hover:scale-105">
                  Transforming Spaces with Urban Haus
                </h2>
                <a
                  className="btn btn-white mt-8 px-6 py-3 rounded-full text-lg font-semibold transition-colors duration-300 ease-in-out hover:bg-gray-200 hover:text-gray-800"
                  href="how-we-work"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  Explore Our Services
                </a>
              </div>
              <div className="mt-8 lg:col-5 lg:mt-0" data-aos="fade-left" data-aos-delay="400">
                <p className="text-lg text-white leading-relaxed">
                  At Urban Haus, we specialize in creating beautiful, high-quality interiors that enhance the way you live and work. Our team is dedicated to bringing your vision to life with innovative designs and exceptional craftsmanship.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToContact;
