// Process.jsx
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const ServiceSection = ({ titleId, title, imageSrc, imageAlt, items, description }) => (
  <div className="row gx-5 mt-16 items-center">
    <div className="lg:col-7 lg:order-2">
      <div className="relative">
        <img className="w-full object-contain" src={imageSrc} alt={imageAlt} />
      </div>
    </div>
    <div className="mt-6 lg:col-5 lg:order-1 lg:mt-0">
      <div className="text-container">
        <h2 id={titleId} className="lg:text-4xl">{title}</h2>
        <ul className="mt-6 text-dark lg:-ml-4" aria-labelledby={titleId}>
          {items.map((item, index) => (
            <li key={index} className="mb-2 flex items-center rounded px-4">
              <img className="mr-3" src="images/icons/checkmark-circle.svg" alt="Checkmark icon" />
              {item}
            </li>
          ))}
        </ul>
        <p className="mt-6 font-medium text-dark">{description}</p>
      </div>
    </div>
  </div>
);

const Process = ({ data }) => { // Accept data as a prop
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
      once: false, // Animation only occurs once
    });
  }, []);

  return (
    <section aria-labelledby="process-title">
      <div className="container">
        {data.sections.map((section, index) => (
          <ServiceSection
            key={index}
            titleId={section.titleId}
            title={section.title}
            imageSrc={section.imageSrc}
            imageAlt={section.imageAlt}
            items={section.items}
            description={section.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Process;
