import React from 'react';

const ContactUs = () => {
  return (
    <div className="relative overflow-hidden">
      {/* Floating Assets */}
      <img
        className="floating-bubble-1 absolute right-0 top-0 -z-10 animate-float"
        src="images/floating-bubble-1.svg"
        alt="Floating Bubble 1"
      />
      <img
        className="floating-bubble-2 absolute left-0 top-[387px] -z-10 animate-float"
        src="images/floating-bubble-2.svg"
        alt="Floating Bubble 2"
      />
      <img
        className="floating-bubble-3 absolute right-0 top-[605px] -z-10 animate-float"
        src="images/floating-bubble-3.svg"
        alt="Floating Bubble 3"
      />

      {/* Common Hero */}
      <section className="page-hero pt-16 pb-6 animate-fade-in">
        <div className="container mx-auto text-center">
          <h1 className="text-4xl font-bold mb-5 mt-8 text-gray-800">
            Get in Touch with Our <br />
            Urban HAUS Team
          </h1>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="section pt-0 bg-white shadow-md animate-fade-in">
        <div className="container mx-auto">
          <div className="grid gap-8 md:grid-cols-2">
            <div className="relative inline-flex pl-5 pb-5 md:order-2 md:pt-9">
              <div className="contact-img relative">
                <img 
                  src="images/conatct-img.gif" 
                  alt="Contact Us" 
                  className="transition-transform duration-500 hover:scale-105" 
                />
                <img
                  className="absolute bottom-0 left-0 -z-10 h-14 w-14 animate-pulse"
                  src="images/shape-2.svg"
                  alt="Decoration Shape"
                />
              </div>
            </div>
            <div className="md:order-1">
              <form className="lg:max-w-lg mx-auto bg-gray-50 p-6 rounded-lg shadow-md transition-transform duration-500 hover:scale-105" action="#" method="POST">
                <div className="form-group mb-5">
                  <label className="form-label text-lg font-semibold mb-2" htmlFor="name">
                    Full Name
                  </label>
                  <input
                    className="form-control w-full p-3 border rounded-lg transition-shadow duration-300 hover:shadow-lg"
                    type="text"
                    id="name"
                    placeholder="Your Full Name"
                  />
                </div>
                <div className="form-group mb-5">
                  <label className="form-label text-lg font-semibold mb-2" htmlFor="email">
                    Email Address
                  </label>
                  <input
                    className="form-control w-full p-3 border rounded-lg transition-shadow duration-300 hover:shadow-lg"
                    type="email"
                    id="email"
                    placeholder="Your Email Address"
                  />
                </div>
                <div className="form-group mb-5">
                  <label className="form-label text-lg font-semibold mb-2" htmlFor="service">
                    Service Interested In
                  </label>
                  <select name="service" id="service" className="form-select w-full p-3 border rounded-lg transition-shadow duration-300 hover:shadow-lg" required>
                    <option value="">Select a Service</option>
                    <option value="detailed-furniture-drawing">Detailed Furniture Drawing</option>
                    <option value="interior-designing">Interior Designing</option>
                    <option value="general-consulting">General Consulting</option>
                  </select>
                </div>
                <div className="form-group mb-5">
                  <label className="form-label text-lg font-semibold mb-2" htmlFor="message">
                    Message Here
                  </label>
                  <textarea
                    className="form-control w-full p-3 border rounded-lg transition-shadow duration-300 hover:shadow-lg"
                    id="message"
                    cols="30"
                    rows="10"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <input
                  className="btn btn-primary block w-full py-3 px-4 rounded-lg bg-primary text-white hover:bg-blue-700 transition-colors"
                  type="submit"
                  value="Send Message"
                />
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Details Section */}
      <section className="section py-12  animate-fade-in">
  <div className="container mx-auto px-4 md:px-8 text-center">
    <h2 className="text-3xl md:text-4xl font-extrabold mb-6 text-gray-900">
      Contact Details
    </h2>
    <p className="mb-6 text-lg md:text-xl text-gray-600">
      For any inquiries, please feel free to reach out to us:
    </p>
    <address className="mb-6">
      <p className="text-xl md:text-2xl font-semibold text-gray-800">
        Urban Haus
      </p>
      <p className="text-gray-700 text-lg md:text-xl">
        123 Interior Design St, Suite 456
      </p>
      <p className="text-gray-700 text-lg md:text-xl">
        Design City, DC 78901
      </p>
      <p className="text-gray-700 text-lg md:text-xl">
        Email: 
        <a 
          href="mailto:ajay@urbanhaus.co.in" 
          className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
        >
          ajay@urbanhaus.co.in
        </a>
      </p>
    </address>
    <p className="text-lg md:text-xl text-gray-600">
      We look forward to hearing from you!
    </p>
  </div>
</section>

      {/* Google Maps Section */}
      <section className="section py-8 bg-white animate-fade-in">
        <div className="container mx-auto">
          <div className="relative overflow-hidden rounded-lg shadow-md">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15542.542294395194!2d80.1402697!3d13.1222576!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6acf9562a8948775%3A0x86056ec3913f5c5f!2sUrban%20Haus%20-%20The%20Interior%20Design%20Company!5e0!3m2!1sen!2sin!4v1721946574295!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps Location"
            ></iframe>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
