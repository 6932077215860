// src/routes.js
import React from 'react';
import { Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Blogs from './pages/Blogs';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Store from './pages/store';

const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/how-we-work',
    element: <Services />,
  },
  {
    path: '/blogs',
    element: <Blogs />,
  },
  {
    path: '/blog/:id',
    element: <Blog />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/store',
    element: <Store />,
  },
];

export default routes;
