import React from 'react';
import CountUp from 'react-countup';

const CounterSection = () => (
  <div className="container">
    <div className="counter mt-16">
      <div className="row mx-0 rounded-[20px] bg-white px-10 shadow-lg lg:py-10">
        {[
          { count: 250, text: 'Customers', color: '#A3A1FB' },
          { count: 440, text: 'Designed', color: '#5EE2A0' },
          { count: 400, text: 'Successful Constructions', color: '#FF6F61' },
          { count: 290, text: 'On Time Completion', color: '#FEC163' },
        ].map((item, index) => (
          <div
            key={index}
            className={`border-border px-10 py-10 text-center sm:col-6 lg:col-3 ${
              index < 3 ? 'lg:border-r' : ''
            } lg:py-0`}
            data-aos="fade-up"
            data-aos-delay={`${index * 100}`} // Staggered animation delays
          >
            <h2 className="text-3xl font-bold">
              <CountUp
                start={0}
                end={item.count}
                duration={5} // Increased duration to slow down the animation
                separator=","
                suffix="+"
                style={{ color: item.color }}
              />
            </h2>
            <p className="text-lg">{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default CounterSection;
