// ServicesHeroComponent.jsx
import React from 'react';
import './hero.css'
const ServicesHeroComponent = ({ data }) => {
  return (
    <section className="relative h-screen w-full flex items-center justify-center text-center bg-cover bg-center hero-background">
      <div className="absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-75"></div>
      <main className="px-4 sm:px-6 lg:px-8 z-10">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight leading-10 font-medium sm:text-5xl text-white sm:leading-none md:text-6xl">
            <span className="text-primary font-bold">{data.title}</span>
          </h1>
          <p className="mt-3 text-white sm:mt-5 sm:text-md sm:max-w-xl sm:mx-auto md:mt-5">
            {data.description}
          </p>
          <div className="mt-5 sm:mt-8 sm:flex justify-center">
            <div className="rounded-md shadow">
              <a
                href={data.button1.link}
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-regular rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:border-primary focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:px-10"
              >
                {data.button1.text}
              </a>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <a
                href={data.button2.link}
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-regular rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:border-primary focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:px-10"
              >
                {data.button2.text}
              </a>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default ServicesHeroComponent;
