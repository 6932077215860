import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaFacebook,FaLinkedin,FaQuora, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer bg-theme-light/50">
      <div className="container">
        <div className="row gx-5 pb-10 pt-[52px]">
          <div className="col-12 mt-12 md:col-6 lg:col-3">
            <Link to="/">
              <img src="/images/logo.svg" alt="Urban Haus Logo" />
            </Link>
            <p className="mt-6">
              Urban Haus is your premier destination for innovative interior design and high-quality furniture. We transform spaces with creativity and craftsmanship.
            </p>
          </div>
          <div className="col-12 mt-12 md:col-6 lg:col-3">
            <h6>Contact Us</h6>
            <p>Email: ajay@urbanhaus.store</p>
            <p>Phone: <a href="https://wa.me/916379850562" target="_blank" rel="noopener noreferrer">+91 6379850562 (WhatsApp)</a></p>
            <ul className="social-icons mt-4 lg:mt-6">
              <li>
              <a href="https://www.instagram.com/urban__haus/?fbclid=IwZXh0bgNhZW0CMTEAAR1zbyl4be0LgghcKbIuwTMZ4NwOB1w9dE9dqQsaHNBxIP-Di1WmiB9tBZc_aem_lbBYCLZn60Kd6vFbK9jQeA" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={19} color="#222222" />
</a>

              </li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=61562741047915" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={19} color="#222222" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/uraban-haus-chennai/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={19} color="#222222" />
                </a>
              </li>
              <li>
                <a href="https://www.quora.com/profile/Urban-Haus-1" target="_blank" rel="noopener noreferrer">
                <FaQuora size={19} color="#222222" />
                </a>
              </li>
              <li>
                <a href="https://wa.me/916379850562" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp size={19} color="#222222" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 mt-12 md:col-6 lg:col-3">
            <h6>Quick Links</h6>
            <ul>
            
              <li><Link to="https://urbanhaus.store/">UrbanHAUS Store</Link></li>
              <li><Link to="http://studio.urbanhaus.co.in/">UrbanHaus Studio</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
      
          <div className="col-12 mt-12 md:col-6 lg:col-3">
            <h6>Location & Map</h6>
            <p>Thirumullaivoil, Ambattur, Chennai</p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15542.542825588027!2d80.1403703!3d13.1222492!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6acf9562a8948775%3A0x86056ec3913f5c5f!2sUrban%20Haus%20-%20The%20Interior%20Design%20Company!5e0!3m2!1sen!2sin!4v1723149285470!5m2!1sen!2sin"
              width="200"  // Adjusted to be responsive
              height="200"  // Adjust height as needed
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Urban Haus Location"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="container max-w-[1440px]">
  <div className="footer-copyright mx-auto border-t border-border pb-10 pt-7 text-center">
    <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      Designed And Developed by 
      <a href="https://imaggar.in/" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
        <img src="https://imaggar.in/logo.png" alt="Imaggar Technologies Logo" style={{ height: '20px', marginRight: '5px' }} />
       
      </a>
    </p>
  </div>
</div>

    </footer>
  );
}

export default Footer;
