import React from 'react'
import HeroSection from '../components/about/HeroSection'
import CounterSection from '../components/about/CounterSection'
import GallerySection from '../components/about/GallerySection'
import ValuesSection from '../components/about/ValuesSection'

function about() {
  return (
    <div>
<HeroSection />
<GallerySection />
<ValuesSection />


    </div>
  )
}

export default about