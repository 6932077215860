import React from 'react';
import { blogPosts } from '../data'; // Adjust the path as needed
import { useParams } from 'react-router-dom';

const BlogHeader = ({ title, author, date, readTime }) => (
  <header className="blog-header">
    <h1 className="text-3xl font-bold">{title}</h1>
    <div className="mt-4 flex items-center space-x-2">
    
      <div className="text-sm">
        <p className="text-dark font-medium">{author}</p>
        <span>{date} • {readTime} Min read</span>
      </div>
    </div>
  </header>
);

const BlogContent = ({ content }) => (
  <div className="blog-content" dangerouslySetInnerHTML={{ __html: content }} />
);

const Blog = () => {
  const { id } = useParams();
  const post = blogPosts.find(post => post.id === id);

  if (!post) {
    return (
      <div className="container mx-auto p-4">
        <article className="bg-white shadow-md rounded-lg p-8">
          <h1 className="text-3xl font-bold">Blog Post Not Found</h1>
          <p className="mt-4">We couldn't find the blog post you're looking for. Please check the URL or return to the blog page.</p>
        </article>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <article className="bg-white shadow-md rounded-lg p-8">
       
        <BlogHeader 
          title={post.title}
          author={post.author}
          date={post.date}
          readTime={post.readTime}
        />
        
          <img className="my-8 w-full rounded-lg" src="images\blog\1.jpg" alt={post.alt || 'Blog post image'} />
       
        <BlogContent content={post.content} />
      </article>
    </div>
  );
};

export default Blog;
