// Services.jsx
import React from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { useEffect } from 'react';

const Services = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
      once: false, // Animation only occurs once
    });
  }, []);
  return (
    <>
      {/* Common Hero */}
      <section  id='services' className="page-hero pb-14 pt-16">
       
     

    

      {/* Features Section */}
      <section className="px-5 py-20 xl:py-[120px]">
        <div className="container">
          <div className="bg-gradient row justify-center rounded-b-[80px] rounded-t-[20px] px-[30px] pb-[75px] pt-16">
            <div className="lg:col-11">
              <div className="row">
                <div className="lg:col-7">
                  <h2 className="h1 text-white">Transforming Spaces with Urban Haus</h2>
                  <a className="btn btn-white mt-8" href="how-it-works.html">Explore Our Services</a>
                </div>
                <div className="mt-8 lg:col-5 lg:mt-0">
                  <p className="text-white">
                    At Urban Haus, we specialize in creating beautiful, high-quality interiors that enhance the way you live and work. Our team is dedicated to bringing your vision to life with innovative designs and exceptional craftsmanship.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
        </section>
    </>
  );
};

export default Services;
