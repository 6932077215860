import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight, FaQuoteRight } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const Reviews = ({ testimonialsData }) => {
  const [index, setIndex] = useState(0);
  const { name, review, profileImage, reviewLink } = testimonialsData[index];

  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS with options
  }, []);

  const checkNumber = (number) => {
    if (number >= testimonialsData.length) {
      return 0;
    } else if (number < 0) {
      return testimonialsData.length - 1;
    }
    return number;
  };

  const nextPerson = () => {
    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    });
  };

  const prevPerson = () => {
    setIndex((index) => {
      let newIndex = index - 1;
      return checkNumber(newIndex);
    });
  };

  const randomPerson = () => {
    let randomNumber = Math.floor(Math.random() * testimonialsData.length);
    if (randomNumber === index) {
      randomNumber = checkNumber(index + 1);
    }
    setIndex(checkNumber(randomNumber));
  };

  return (
    <section className="py-12">
      <h1 className="text-4xl font-bold text-center mb-8" data-aos="fade-up">Testimonials</h1>

      <div className="container mx-auto">
        <article className="review bg-white p-6 rounded-lg shadow-lg text-center transition-transform transform-gpu duration-500 ease-in-out hover:scale-105" data-aos="fade-up" data-aos-delay="100">
          <div className="img-container relative mb-6">
            <img
              src={profileImage}
              alt={name}
              className="w-24 h-24 rounded-full mx-auto border-2 border-gray-300 transition-transform transform-gpu duration-300 ease-in-out hover:scale-110"
              data-aos="fade-up"
              data-aos-delay="200"
            />
            <span className="quote-icon absolute top-0 right-0 transform translate-x-4 -translate-y-4 text-gray-400 text-3xl transition-transform transform-gpu duration-300 ease-in-out hover:rotate-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <FaQuoteRight />
            </span>
          </div>
          <h4 className="author text-xl font-semibold mb-2 text-gray-800 transition-colors duration-300 ease-in-out hover:text-primary" data-aos="fade-up" data-aos-delay="400">
            {name}
          </h4>
          <p className="text-gray-700 mb-4" data-aos="fade-up" data-aos-delay="500">{review}</p>
          <div className="button-container flex justify-center space-x-4 mb-4">
            <button
              className="prev-btn bg-gray-200 p-2 rounded-full text-gray-600 hover:bg-gray-300 transition-colors duration-300 ease-in-out transform hover:scale-110"
              onClick={prevPerson}
              aria-label="Previous review"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <FaChevronLeft />
            </button>
            <button
              className="next-btn bg-gray-200 p-2 rounded-full text-gray-600 hover:bg-gray-300 transition-colors duration-300 ease-in-out transform hover:scale-110"
              onClick={nextPerson}
              aria-label="Next review"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <FaChevronRight />
            </button>
          </div>
          <a
            href={reviewLink}
            className="random-btn bg-primary text-white py-2 px-4 rounded-full transition-transform duration-300 ease-in-out transform hover:scale-110 hover:bg-white hover:text-primary hover:shadow-lg hover:border-2"
            aria-label="Visit review"
            data-aos="fade-up"
            data-aos-delay="800"
          >
            Visit Review
          </a>
        </article>
      </div>
    </section>
  );
};

export default Reviews;
