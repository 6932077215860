// Services.jsx
import React from 'react';
import Process from '../components/services/Process'
import ServicesComponent from '../components/services/ServicesComponent';
import ServicesHeroComponent from '../components/services/servicesHeroComponents';
import { heroData, processData, services } from '../data/data'; // Import data

const Services = () => {
  return (
    <div>
      <ServicesHeroComponent data={heroData} /> {/* Corrected component name */}
      <Process data={processData} />
      <ServicesComponent data={services} />
    </div>
  );
};

export default Services;
