import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import routes from './routes';
import Navbar from './components/comman/Navbar';
import Footer from './components/comman/Footer';

const App = () => {
  return (
    <> 
    <Router>
    <Navbar />
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      <Footer />
      {/* Meta Pixel Code */}
      
      {/* End Meta Pixel Code */}
    </Router></>
  );
};

export default App;