import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const KeyFeatures = ({ features }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
      once: false, // Animation only occurs once
    });
  }, []);

  return (
    <section className="section key-feature relative py-20">
      <img
        className="absolute left-0 top-0 -z-[1] -translate-y-1/2"
        src="images/icons/feature-shape.svg"
        alt="Decorative Feature Shape"
      />
      <div className="container">
        <div className="row justify-between text-center lg:text-start">
          <div className="lg:col-5">
            <h2 className="text-3xl font-bold" data-aos="fade-up">
              Key Features of Our Interior Design Solutions
            </h2>
          </div>
          <div className="mt-6 lg:col-5 lg:mt-0" data-aos="fade-up" data-aos-delay="200">
            <p className="text-lg leading-relaxed">
              At Urban Haus, we specialize in crafting distinctive interiors that not only reflect your style but also enhance functionality. Our all-encompassing design solutions offer a blend of creativity and practicality, tailored to elevate every aspect of your living and working spaces.
            </p>
          </div>
        </div>
        <div className="key-feature-grid mt-10 grid grid-cols-2 gap-7 md:grid-cols-3 xl:grid-cols-4">
          {features.map((feature, index) => (
            <div
              key={index}
              className="feature-item p-6 bg-white shadow-lg rounded-lg"
              data-aos="fade-up"
              data-aos-delay={feature.delay}
            >
              <h3 className="text-xl font-semibold">{feature.title}</h3>
              <p className="text-base">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyFeatures;
