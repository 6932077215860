import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import CounterSection from './CounterSection';

const HeroSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
      once: true, // Animation only occurs once
    });
  }, []);

  return (
    <section className="page-hero py-16 relative">
      <div className="container">
        <div className="page-hero-content mx-auto max-w-[768px] text-center">
          <h1 className="mb-5 mt-8 text-4xl font-bold" data-aos="fade-up">
            Transforming Spaces with Style
          </h1>
          <p className="text-lg leading-relaxed" data-aos="fade-up" data-aos-delay="200">
            Urban Haus blends innovation and craftsmanship to redefine interior design and furniture. Our mission is to create spaces that inspire and elevate living experiences.
          </p>
          <div className="mt-11 flex justify-center sm:flex-row flex-col">
            <a
              className="btn btn-primary m-3 block sm:inline-block transition-transform duration-300 ease-in-out transform hover:scale-105"
              href="#"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              Discover Our Collection
            </a>
            <a
              className="btn btn-outline-primary m-3 block min-w-[160px] sm:inline-block transition-transform duration-300 ease-in-out transform hover:scale-105"
              href="#"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
      <CounterSection />
    </section>
  );
};

export default HeroSection;
