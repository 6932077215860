// data.js
export const urbanHausMessage = {
  title: "Elevate Your Interior Design & Operations with Urban Haus",
  description: "Elevate your space with Urban Haus's seamless design and innovation, offering a fully integrated suite that makes everything convenient and hassle-free, from concept to completion."
};

export const keyFeaturesData = [
  {
    title: "Innovative Design Solutions",
    description: "Our innovative approach combines modern aesthetics with practical design to create unique spaces that are both beautiful and functional.",
    delay: 300,
  },
  {
    title: "Premium Materials",
    description: "We use only the highest quality materials to ensure that every detail of your space is crafted to perfection and built to last.",
    delay: 400,
  },
  {
    title: "Customized Solutions",
    description: "Our team provides personalized design solutions that cater specifically to your needs, ensuring that your space is a true reflection of your vision.",
    delay: 500,
  },
  {
    title: "End-to-End Service",
    description: "From initial consultation to project completion, we offer a seamless experience, managing every aspect of your interior design project with expertise and care.",
    delay: 600,
  },
];

export const servicesData = {
  heading: "Transforming Spaces with Urban Haus",
  buttonText: "Explore Our Services",
  buttonLink: "how-it-works.html",
  description: "At Urban Haus, we specialize in creating beautiful, high-quality interiors that enhance the way you live and work. Our team is dedicated to bringing your vision to life with innovative designs and exceptional craftsmanship."
};
// data.js
export const testimonialsData = [
  {
    name: 'Hariharan Annamalai',
    review: 'Urban Haus delivers exceptional value for money. Their furniture is both stylish and durable, all at remarkably affordable prices. The service was impeccable and the overall experience was thoroughly satisfying.',
    profileImage: 'https://lh3.googleusercontent.com/a-/ALV-UjX09kL2ZjbtvYNDUJgz7SiqiND-bZL9adMHh4KhqABSmCFDZHsxig=w75-h75-p-rp-mo-br100',
    reviewLink: 'https://maps.app.goo.gl/RxKg4ymcWRBaWUGr9'
  },
  {
    name: 'Tamilpriyan',
    review: 'Urban Haus is totally epic! They’ve got amazing quality furniture that’s super affordable. Their service was super smooth and easy. Totally worth it!',
    profileImage: 'https://lh3.googleusercontent.com/a/ACg8ocJyHE1A4i37lPQe_I_4zIqqcDIoqWkrkLV13a5KVt9i4yBzdaw=s45-c-rp-mo-br100',
    reviewLink: 'https://maps.app.goo.gl/hcRAxwiobre28T2F8'
  },
  {
    name: 'Nivetha Saravanan',
    review: 'Urban Haus is a great find! They offer quality furniture at prices that are easy on the wallet. Their service was top-notch and made everything hassle-free.',
    profileImage: 'https://lh3.googleusercontent.com/a/ACg8ocJ6uy6ghqklCpsVGc8Css6f10uaKwhCauyj-LH6-EjNVVJ-cA=s45-c-rp-mo-br100',
    reviewLink: 'https://maps.app.goo.gl/o6RmdK4pDj2rtVZs6'
  },
  {
    name: 'Nithish Kumar',
    review: 'Urban Haus exceeded my expectations! Affordable prices with high-quality materials. Their service was outstanding and very budget-friendly.',
    profileImage: 'https://lh3.googleusercontent.com/a-/ALV-UjVx0GySz4VvGacvQZPHMY1Dq6Tt2E64ooLP5M6_HfIb-xK2JPFG=s45-c-rp-mo-br100',
    reviewLink: 'https://maps.app.goo.gl/FVUMP9DemR4i8SEk9'
  },
  {
    name: 'Emalda Rani',
    review: 'Urban Haus is the real MVP! Their furniture is affordable and high-quality. The whole process was smooth and their service was top-notch. Check them out!',
    profileImage: 'https://lh3.googleusercontent.com/a-/ALV-UjVhrEnwtTvPyI4bcWy12WBrGGLgWGQADPt7RV3OD-K_S3gC7qQ4=s45-c-rp-mo-br100',
    reviewLink: 'https://maps.app.goo.gl/S74xCMWRMAsMcxK98'
  },
  {
    name: 'Dhiva Gar',
    review: 'Urban Haus is straight fire! Affordable prices and high-quality gear. Their customer service is awesome and made everything super easy. Highly recommend!',
    profileImage: 'https://lh3.googleusercontent.com/a/ACg8ocK0P5FY_zDr5TUVJZQGO6Rjep6sGIKKWAyDgIA7Cl49inHZdg=s45-c-rp-mo-br100',
    reviewLink: 'https://maps.app.goo.gl/PUEtHu3fFjKUneXX9'
  }
];


export const heroData = {
  title: "Welcome to Urban Haus",
  subtitle: "Redefining Spaces with Elegance",
  description: "Discover the art of bespoke interiors and furniture design. At Urban Haus, we blend modern aesthetics with timeless elegance to create spaces that inspire.",
  button1: {
    text: "Get Started",
    link: "#services",
  },
  image: './images/banner-img.png',
  button2: {
    text: "Live Demo",
    link: "/contact",
  },
  additionalTitle: "Our Structured Approach Helps You Navigate Uncertainties",
  additionalDescription: "Urban Haus is dedicated to creating bespoke interiors and furniture that redefine living spaces. With a passion for craftsmanship and attention to detail, we blend modern aesthetics with timeless elegance. Our mission is to inspire and elevate every environment we touch, from residential homes to commercial spaces. Explore our curated collections and experience the artistry of Urban Haus today."
};


export const processData = {
  sections: [
    {
      titleId: "interior-design-title",
      title: "Interior Design Services",
      imageSrc: "images/3.gif",
      imageAlt: "Interior Design Services",
      items: [
        "Residential Interior Design",
        "Commercial Interior Design",
        "Customized Interior Solutions"
      ],
      description: "Transform your space with personalized interior design solutions."
    },
    {
      titleId: "furniture-design-title",
      title: "Furniture Design Services",
      imageSrc: "images/2.gif",
      imageAlt: "Furniture Design Services",
      items: [
        "Custom Furniture Design",
        "Furniture Refinishing and Restoration",
        "Space Optimization Solutions",
        "Tailored Furniture Layouts"
      ],
      description: "Elevate your space with custom-designed furniture tailored to your needs."
    },
    {
      titleId: "consultation-title",
      title: "Consultation Services",
      imageSrc: "images/3.gif",
      imageAlt: "Consultation Services",
      items: [
        "Space Planning and Layout",
        "Color Consultation",
        "Material and Finish Selection"
      ],
      description: "Receive expert advice and guidance to bring your vision to life."
    }
  ]
};



export const services = {
  hero: {
    title: "Transforming Spaces with Urban Haus",
    description: "At Urban Haus, we specialize in creating beautiful, high-quality interiors that enhance the way you live and work. Our team is dedicated to bringing your vision to life with innovative designs and exceptional craftsmanship.",
    button: {
      text: "Explore Our Services",
      link: "how-it-works.html"
    }
  }
};
